import React from 'react'
import { Select } from 'antd'
import useDebounce from 'helpers/useDebounce'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { useSupplierOptionsQuery } from 'app/api/suppliers-api'

import { SupplierOption } from 'shared/types'

type Props = {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: SupplierOption
  supplierId?: string
  size?: SizeType
  onChange?: (value?: SupplierOption) => void
}

const SupplierOptions = React.memo((props: Props) => {
  const { placeholder, disabled, className, value: selectedSupplier, size, supplierId, onChange } = props

  const [searchText, setSearchText] = React.useState<string>(supplierId || '')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: supplierOptions } = useSupplierOptionsQuery({ searchText: debouncedSearchText, size: 30 })

  const handleSelect = (_value: string, option: SupplierOption) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={supplierOptions?.map((supplier) => ({ ...supplier, value: supplier.id, label: supplier.displayName } as SupplierOption))}
      className={className}
      value={supplierId || selectedSupplier?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
})

export default SupplierOptions
