import React from 'react'
import { Select } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import useDebounce from 'helpers/useDebounce'
import { useDesignTemplateOptionsQuery } from 'app/api/design-templates-api'

type Props = {
  supplierId?: string
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Option
  size?: SizeType
  onChange?: (value?: Pto.Option) => void
}

const DesignTemplateOptions = React.memo((props: Props) => {
  const { supplierId, placeholder, disabled, className, value: selectedTemplate, size, onChange } = props

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: templateOptions } = useDesignTemplateOptionsQuery(
    {
      supplierId: supplierId!,
      searchText: debouncedSearchText,
      size: 30
    },
    { skip: !supplierId }
  )

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={templateOptions}
      className={className}
      value={selectedTemplate?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
})

export default DesignTemplateOptions
