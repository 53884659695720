import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Pto, Role } from '@merchx-v3/pto'
import { Menu, MenuProps } from 'antd'
import {
  AppstoreOutlined,
  BgColorsOutlined,
  CameraOutlined,
  LogoutOutlined,
  HighlightOutlined,
  PartitionOutlined,
  RocketOutlined,
  ShoppingOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { authProvider } from 'app/auth/auth-provider'
import { AppRoutes } from '../Router'

import { useUser } from 'app/auth'

import styles from './SiderMenu.module.scss'
import { useSupplierContext } from 'components/SupplierContext/context'

type Props = {
  collapsed: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const SiderMenu = ({ collapsed }: Props) => {
  const location = useLocation()
  const [user] = useUser()
  const { settings, supplierId } = useSupplierContext()

  const [currentPath, setCurrentPath] = useState<string[]>(location.pathname.split('/'))
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const submenus = ['customers', 'settings']

  const getMenu = useCallback(() => {
    const isAutomatedIntegrations = settings.supplierOrderFlow === Pto.Suppliers.SupplierOrderFlow.AutomatedIntegration

    return [
      getItem(<Link to={`./${AppRoutes.dashboard}`}>Dashboard</Link>, AppRoutes.dashboard, <AppstoreOutlined />),
      getItem('Customers', AppRoutes.customers, <TeamOutlined />, [
        getItem(<Link to={`./${AppRoutes.customers}`}>List</Link>, AppRoutes.customers),
        getItem(<Link to={`./${AppRoutes.customerReport}`}>Report</Link>, AppRoutes.customerReport)
      ]),
      getItem(<Link to={`./${AppRoutes.products}`}>Products V2</Link>, AppRoutes.products, <SkinOutlined />),
      getItem(<Link to={`./${AppRoutes.carts}`}>Carts</Link>, AppRoutes.carts, <ShoppingCartOutlined />),
      getItem(<Link to={`./${AppRoutes.supplierOrders}`}>Orders</Link>, AppRoutes.supplierOrders, <ShoppingOutlined />),
      isAutomatedIntegrations ? getItem(<Link to={`./${AppRoutes.supplierOrderDesigns}`}>Design</Link>, AppRoutes.supplierOrderDesigns, <CameraOutlined />) : null,
      isAutomatedIntegrations ? getItem(<Link to={`./${AppRoutes.supplierOrderFulfillments}`}>Fulfillment</Link>, AppRoutes.supplierOrderFulfillments, <BgColorsOutlined />) : null,
      isAutomatedIntegrations ? getItem(<Link to={`./${AppRoutes.supplierOrderShippings}`}>Shipping</Link>, AppRoutes.supplierOrderShippings, <RocketOutlined />) : null,
      getItem(<Link to={`./${AppRoutes.sagas}`}>Sagas</Link>, AppRoutes.sagas, <TeamOutlined />),
      getItem('Settings', AppRoutes.settings, <SettingOutlined />, [
        getItem(<Link to={`./${AppRoutes.settings}/${supplierId}`}>Supplier</Link>, AppRoutes.settings, <TeamOutlined />),
        getItem(<Link to={`./${AppRoutes.attributes}`}>Attributes</Link>, AppRoutes.attributes, <PartitionOutlined />),
        getItem(<Link to={`./${AppRoutes.categories}`}>Categories</Link>, AppRoutes.categories, <AppstoreOutlined />),
        getItem(<Link to={`./${AppRoutes.techniques}`}>Techniques</Link>, AppRoutes.techniques, <HighlightOutlined />),
        getItem(<Link to={`./${AppRoutes.users}`}>Users</Link>, AppRoutes.users, <TeamOutlined />)
      ]),
      getItem(<span onClick={() => authProvider.signOut()}>Logout</span>, 'logout', <LogoutOutlined />)
    ]
  }, [settings.supplierOrderFlow, supplierId])

  useEffect(() => {
    const openedKey = currentPath.filter((path) => submenus.indexOf(path) !== -1)
    setOpenKeys(openedKey)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMenuClick = (e: any) => {
    if (currentPath !== e.key) {
      setCurrentPath(e.key)
    }
  }

  const onOpenChange = (items: string[]) => {
    setOpenKeys(items)
  }

  let menuItems = [] as MenuItem[]
  if (user?.roles.includes(Role.SystemAdmin)) {
    menuItems = getMenu()
  }
  if (user?.roles.includes(Role.User)) {
    menuItems = getMenu()
  }

  return (
    <div className={styles.container}>
      <Menu selectedKeys={currentPath} openKeys={openKeys} mode="inline" theme="light" items={menuItems} onOpenChange={onOpenChange} onClick={handleMenuClick} />
    </div>
  )
}

export default SiderMenu
