import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SupplierId = string
type SupplierCenterId = string
type CorrelationId = string
type CartId = string
type ProductLineId = string
type ServiceLineId = string
type DesignLineId = string

export const cartsApi = createApi({
  reducerPath: 'cartsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Carts', 'ShippingOptions', 'FulfillmentOptions', 'DesignOptions'],
  endpoints: (builder) => ({
    cart: builder.query<Pto.Carts.Checkout.Cart, CorrelationId>({
      query: (correlationId) => ({
        url: `/carts/${correlationId}`
      }),
      providesTags: (cartDetails, _error, correlationId) => [{ type: 'Carts', id: correlationId }]
    }),
    cartList: builder.query<Pto.Carts.List, Pto.Carts.ListArgs>({
      query: (params) => ({
        url: '/supplier-carts',
        params
      }),
      providesTags: (cartsListData, _error, _args) =>
        getProvidedTags(
          'Carts',
          'LIST',
          cartsListData?.items.map((item) => item.id)
        )
    }),
    cartListBadges: builder.query<Pto.Carts.ListBadges, Pto.Carts.ListBadgeArgs>({
      query: (params) => ({
        url: '/supplier-carts/list-badges',
        params
      })
    }),
    createCart: builder.mutation<string, Pto.Carts.Shared.Create & { correlationId: CorrelationId }>({
      query: ({ correlationId, ...body }) => ({
        url: `/carts/${correlationId}`,
        body,
        method: 'POST'
      })
    }),
    createCartForSupplier: builder.mutation<string, Pto.Carts.Shared.Create & { correlationId: CorrelationId; supplierId: SupplierId; supplierCenterId: SupplierCenterId }>({
      query: ({ correlationId, supplierId, supplierCenterId, ...body }) => ({
        url: `/carts/${correlationId}/supplier/${supplierId}/center/${supplierCenterId}`,
        body,
        method: 'POST'
      })
    }),
    updateCartAddress: builder.mutation<string, Pto.Addresses.ShippingAddress & { correlationId: CorrelationId }>({
      query: ({ correlationId, ...body }) => ({
        url: `/carts/${correlationId}/address`,
        body,
        method: 'PATCH'
      })
    }),
    batchAddProductLines: builder.mutation<string, Pto.Carts.BatchUpdate.ProductLinesWithoutDesign & { correlationId: CorrelationId }>({
      query: ({ correlationId, ...body }) => ({
        url: `/carts/${correlationId}/batch-add-product-lines`,
        body,
        method: 'PUT'
      })
    }),
    changeProductPrice: builder.mutation<string, Pto.Carts.SetProductLinePrice & { cartId: CartId; productLineId: ProductLineId; correlationId: CorrelationId }>({
      query: ({ cartId, productLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/product-line/${productLineId}/price`,
        body,
        method: 'PATCH'
      })
    }),
    changeProductQuantity: builder.mutation<string, Pto.Carts.SetProductLineQuantity & { cartId: CartId; productLineId: ProductLineId; correlationId: CorrelationId }>({
      query: ({ cartId, productLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/product-line/${productLineId}/quantity`,
        body,
        method: 'PATCH'
      })
    }),
    changeProductIsManual: builder.mutation<string, Pto.Carts.SetProductLineIsManual & { cartId: CartId; productLineId: ProductLineId; correlationId: CorrelationId }>({
      query: ({ cartId, productLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/product-line/${productLineId}/is-manual`,
        body,
        method: 'PATCH'
      })
    }),
    changeProductDesign: builder.mutation<string, { cartId: CartId; productLineId: ProductLineId; designLineId: DesignLineId; correlationId: CorrelationId }>({
      query: ({ cartId, productLineId, designLineId }) => ({
        url: `/carts/${cartId}/product-line/${productLineId}/design-line/${designLineId}`,
        method: 'PATCH'
      })
    }),
    removeProduct: builder.mutation<string, { cartId: CartId; productLineId: ProductLineId; correlationId: CorrelationId }>({
      query: ({ cartId, productLineId }) => ({
        url: `/carts/${cartId}/product-line/${productLineId}`,
        method: 'DELETE'
      })
    }),
    addService: builder.mutation<void, Pto.Carts.AddServiceLine & { correlationId: CorrelationId; cartId: CartId }>({
      query: ({ cartId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line`,
        body,
        method: 'PUT'
      })
    }),
    changeServicePrice: builder.mutation<string, Pto.Carts.SetServiceLinePrice & { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}/price`,
        body,
        method: 'PATCH'
      })
    }),
    changeServiceQuantity: builder.mutation<string, Pto.Carts.SetServiceLineQuantity & { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}/quantity`,
        body,
        method: 'PATCH'
      })
    }),
    changeServiceIsManual: builder.mutation<string, Pto.Carts.SetServiceLineIsManual & { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}/is-manual`,
        body,
        method: 'PATCH'
      })
    }),
    changeServiceOption: builder.mutation<string, Pto.Carts.SetServiceLineOption & { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}/option`,
        body,
        method: 'PATCH'
      })
    }),
    changeServiceCustomOption: builder.mutation<string, Pto.Carts.SetServiceLineOption & { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId, correlationId: _correlationId, ...body }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}/custom-option`,
        body,
        method: 'PATCH'
      })
    }),
    shippingServiceOptions: builder.query<Pto.CheckoutServices.ServiceOptionEstimate[], { correlationId: CorrelationId; cartId: CartId }>({
      query: ({ correlationId, cartId }) => ({
        url: `/carts/${correlationId}/${cartId}/shipping-options`
      }),
      providesTags: (cartsListData, _error, _args) =>
        getProvidedTags(
          'ShippingOptions',
          undefined,
          cartsListData?.map((item) => item.name)
        )
    }),
    fulfillmentServiceOptions: builder.query<Pto.CheckoutServices.ServiceOptionEstimate[], { correlationId: CorrelationId; cartId: CartId }>({
      query: ({ correlationId, cartId }) => ({
        url: `/carts/${correlationId}/${cartId}/fulfillment-options`
      }),
      providesTags: (cartsListData, _error, _args) =>
        getProvidedTags(
          'FulfillmentOptions',
          undefined,
          cartsListData?.map((item) => item.name)
        )
    }),
    designServiceOptions: builder.query<Pto.CheckoutServices.ServiceOptionEstimate[], { correlationId: CorrelationId; cartId: CartId }>({
      query: ({ correlationId, cartId }) => ({
        url: `/carts/${correlationId}/${cartId}/design-options`
      }),
      providesTags: (cartsListData, _error, _args) =>
        getProvidedTags(
          'DesignOptions',
          undefined,
          cartsListData?.map((item) => item.name)
        )
    }),
    removeService: builder.mutation<string, { cartId: CartId; serviceLineId: ServiceLineId; correlationId: CorrelationId }>({
      query: ({ cartId, serviceLineId }) => ({
        url: `/carts/${cartId}/service-line/${serviceLineId}`,
        method: 'DELETE'
      })
    }),

    addDesign: builder.mutation<string, Pto.Carts.AddDesignLine & { cartId: CartId; correlationId: CorrelationId }>({
      query: ({ cartId, correlationId, ...body }) => ({
        url: `/carts/${cartId}/design-line`,
        body,
        method: 'PUT'
      })
    }),
    changeDesignName: builder.mutation<string, Pto.Carts.ChangeDesignLineName & { cartId: CartId; correlationId: CorrelationId; designLineId: DesignLineId }>({
      query: ({ cartId, correlationId, designLineId, ...body }) => ({
        url: `/carts/${cartId}/design-line/${designLineId}/name`,
        body,
        method: 'PATCH'
      })
    }),
    addDesignLinePrintArea: builder.mutation<string, Pto.Carts.AddDesignLinePrintArea & { cartId: CartId; correlationId: CorrelationId; designLineId: DesignLineId }>({
      query: ({ cartId, correlationId, designLineId, ...body }) => ({
        url: `/carts/${cartId}/design-line/${designLineId}/print-area`,
        body,
        method: 'PUT'
      })
    }),
    updateDesignLinePrintArea: builder.mutation<string, Pto.Carts.UpdateDesignLinePrintArea & { cartId: CartId; correlationId: CorrelationId; designLineId: DesignLineId }>({
      query: ({ cartId, correlationId, designLineId, ...body }) => ({
        url: `/carts/${cartId}/design-line/${designLineId}/print-area`,
        body,
        method: 'PATCH'
      })
    }),
    removeDesignLinePrintArea: builder.mutation<string, Pto.Carts.RemoveDesignLinePrintArea & { cartId: CartId; correlationId: CorrelationId; designLineId: DesignLineId }>({
      query: ({ cartId, correlationId, designLineId, ...body }) => ({
        url: `/carts/${cartId}/design-line/${designLineId}/print-area`,
        body,
        method: 'DELETE'
      })
    }),
    removeDesign: builder.mutation<string, { cartId: CartId; correlationId: CorrelationId; designLineId: DesignLineId }>({
      query: ({ cartId, designLineId }) => ({
        url: `/carts/${cartId}/design-line/${designLineId}`,
        method: 'DELETE'
      })
    }),

    completePrepaidCart: builder.mutation<string, { correlationId: CorrelationId }>({
      query: ({ correlationId }) => ({
        url: `/carts/${correlationId}/complete-prepaid-cart`,
        method: 'PATCH'
      })
    }),

    takeResponsibility: builder.mutation<string, { supplierCartId: CartId }>({
      query: ({ supplierCartId }) => ({
        url: `/carts/${supplierCartId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibility: builder.mutation<string, { supplierCartId: CartId }>({
      query: ({ supplierCartId }) => ({
        url: `/carts/${supplierCartId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsible: builder.mutation<string, { supplierCartId: CartId; responsibleUserId: string }>({
      query: ({ supplierCartId, responsibleUserId }) => ({
        url: `/carts/${supplierCartId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsible: builder.mutation<string, { supplierCartId: CartId; responsibleUserId: string }>({
      query: ({ supplierCartId, responsibleUserId }) => ({
        url: `/carts/${supplierCartId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateCartComment: builder.mutation<string, { cartId: CartId; comment: string }>({
      query: ({ cartId, comment }) => ({
        url: `/carts/${cartId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Cart') {
    store.dispatch(
      cartsApi.util.invalidateTags([
        { type: 'Carts', id: payload.entityId },
        { type: 'Carts', id: `LIST` }
      ])
    )
  }
}

export const subscribeToCartEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromCartEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useCartQuery,
  useCartListQuery,
  useCartListBadgesQuery,
  useCreateCartMutation,
  useCreateCartForSupplierMutation,
  useUpdateCartAddressMutation,

  useBatchAddProductLinesMutation,
  useChangeProductPriceMutation,
  useChangeProductQuantityMutation,
  useChangeProductIsManualMutation,
  useChangeProductDesignMutation,
  useRemoveProductMutation,

  useAddServiceMutation,
  useChangeServicePriceMutation,
  useChangeServiceQuantityMutation,
  useChangeServiceIsManualMutation,
  useChangeServiceOptionMutation,
  useChangeServiceCustomOptionMutation,
  useShippingServiceOptionsQuery,
  useFulfillmentServiceOptionsQuery,
  useDesignServiceOptionsQuery,
  useRemoveServiceMutation,

  useAddDesignMutation,
  useChangeDesignNameMutation,
  useAddDesignLinePrintAreaMutation,
  useUpdateDesignLinePrintAreaMutation,
  useRemoveDesignLinePrintAreaMutation,
  useRemoveDesignMutation,

  useTakeResponsibilityMutation,
  useDropResponsibilityMutation,
  useAddResponsibleMutation,
  useRemoveResponsibleMutation,
  useUpdateCartCommentMutation,

  useCompletePrepaidCartMutation
} = cartsApi
