import React from 'react'
import { Select } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { SupplierCenterOption } from 'shared/types'

type Props = {
  supplierCenterOptions: Pto.Suppliers.SupplierCenter[]
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: SupplierCenterOption
  supplierCenterId?: string
  size?: SizeType
  onChange?: (value?: SupplierCenterOption) => void
}

const SupplierOptions = React.memo((props: Props) => {
  const { placeholder, disabled, className, value: selectedSupplierCenter, supplierCenterId, supplierCenterOptions, size, onChange } = props

  const [searchText, setSearchText] = React.useState<string>(supplierCenterId || '')

  const handleSelect = (_value: string, option: SupplierCenterOption) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={supplierCenterOptions.map(
        (supplierCenter) => ({ ...supplierCenter, value: supplierCenter.id, label: `${supplierCenter.name} (${supplierCenter.id})` } as SupplierCenterOption)
      )}
      className={className}
      value={supplierCenterId || selectedSupplierCenter?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      showSearch
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
})

export default SupplierOptions
