import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Routes, Route } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import AppRoutes from './AppRoutes'
import Layout from '../Layout'

const InvoiceList = lazy(() => import('interfaces/admin/features/invoices/pages/InvoiceList'))

const Dashboard = lazy(() => import('interfaces/admin/features/dashboard/components/Dashboard'))
const InvoicePaymentCompleted = lazy(() => import('interfaces/admin/features/invoices/pages/InvoicePaymentCompleted'))
const InvoicePaymentFailed = lazy(() => import('interfaces/admin/features/invoices/pages/InvoicePaymentFailed'))
const OrderList = lazy(() => import('interfaces/admin/features/orders/pages/OrderList'))
const PaymentList = lazy(() => import('interfaces/admin/features/payments/pages/PaymentList'))
const AttributeList = lazy(() => import('interfaces/admin/features/attributes/pages/AttributeList'))
const AttributeDetails = lazy(() => import('interfaces/admin/features/attributes/pages/AttributeDetails'))
const CategoryList = lazy(() => import('interfaces/admin/features/categories/pages/CategoriesList'))
const CategoryDetails = lazy(() => import('interfaces/admin/features/categories/pages/CategoriesDetails'))
const SubcategoryDetails = lazy(() => import('interfaces/admin/features/categories/pages/SubcategoriesDetails'))
const TechniqueList = lazy(() => import('interfaces/admin/features/techniques/pages/TechniqueList'))
const TechniqueDetails = lazy(() => import('interfaces/admin/features/techniques/pages/TechniqueDetails'))

const FulfillmentPluginList = lazy(() => import('interfaces/admin/features/plugins/pages/FulfillmentPluginList'))
const ShippingPluginList = lazy(() => import('interfaces/admin/features/plugins/pages/ShippingPluginList'))
const FulfillmentPlugin = lazy(() => import('interfaces/admin/features/plugins/pages/FulfillmentPlugin'))
const ShippingPlugin = lazy(() => import('interfaces/admin/features/plugins/pages/ShippingPlugin'))
const EditSupplierSelect = lazy(() => import('interfaces/admin/features/plugins/pages/EditSupplierSelect'))
const UserDetails = lazy(() => import('interfaces/admin/features/user/pages/UserDetails'))
const UserList = lazy(() => import('interfaces/admin/features/user/pages/UserList'))
const CreateUser = lazy(() => import('interfaces/admin/features/user/pages/CreateUser'))
const SagaList = lazy(() => import('interfaces/admin/pages/SagaList'))
const SagaDetails = lazy(() => import('interfaces/admin/pages/SagaDetails'))

// from shared pages
const CartList = lazy(() => import('interfaces/sharedPages/CartList'))
const CartDetails = lazy(() => import('interfaces/sharedPages/CartDetails'))
const CustomersList = lazy(() => import('interfaces/sharedPages/CustomerList'))
const CustomersReport = lazy(() => import('interfaces/sharedPages/CustomersReport'))
const CustomerDetails = lazy(() => import('interfaces/sharedPages/CustomerDetails'))
const DesignRequest = lazy(() => import('interfaces/sharedPages/DesignRequest'))
const DesignRequestHistory = lazy(() => import('interfaces/sharedPages/DesignRequestHistory'))
const DesignRequestList = lazy(() => import('interfaces/sharedPages/DesignRequestList'))
const EcomProductList = lazy(() => import('interfaces/sharedPages/EcomProductList'))
const EcomProduct = lazy(() => import('interfaces/sharedPages/EcomProduct'))
const EditProject = lazy(() => import('interfaces/sharedPages/EditProject'))
const FulfillmentTask = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillment'))
const FulfillmentTaskHistory = lazy(() => import('interfaces/sharedPages/FulfillmentHistory'))
const InvoiceDetails = lazy(() => import('interfaces/sharedPages/InvoiceDetails'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))
const ProjectList = lazy(() => import('interfaces/sharedPages/ProjectList'))
const ProjectHistory = lazy(() => import('interfaces/sharedPages/ProjectHistory'))
const ShippingTask = lazy(() => import('interfaces/sharedPages/ShippingTask'))
const ShippingHistory = lazy(() => import('interfaces/sharedPages/ShippingHistory'))

const ProductV2List = lazy(() => import('interfaces/sharedPages/ProductsList'))
const ProductV2Details = lazy(() => import('interfaces/sharedPages/ProductDetails'))
const CustomGatewaySettingsList = lazy(() => import('interfaces/sharedPages/CustomGatewaySettingsList'))
const CustomGatewaySettingDetails = lazy(() => import('interfaces/sharedPages/CustomGatewaySettingDetails'))

const SupplierOrder = lazy(() => import('interfaces/sharedPages/SupplierOrder'))
const SupplierOrderList = lazy(() => import('interfaces/sharedPages/SupplierOrderList'))
const SupplierOrderDesign = lazy(() => import('interfaces/sharedPages/SupplierOrderDesign'))
const SupplierOrderDesignList = lazy(() => import('interfaces/sharedPages/SupplierOrderDesignList'))
const SupplierOrderFulfillment = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillment'))
const SupplierOrderFulfillmentList = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillmentList'))
const SupplierOrderShipping = lazy(() => import('interfaces/sharedPages/SupplierOrderShipping'))
const SupplierOrderShippingList = lazy(() => import('interfaces/sharedPages/SupplierOrderShippingList'))
const SupplierSettingsPage = lazy(() => import('interfaces/sharedPages/SupplierSettingsPage'))

const Router = () => {
  const [user, isLoading] = useUser()

  const supplierId = localStorage.getItem('supplierId')
  const supplierCenterId = localStorage.getItem('supplierId')

  if (
    user &&
    (!supplierId || !supplierCenterId) &&
    !user.roles.includes(Role.SystemAdmin) &&
    !user.claims.some((claim) => claim.claim === Pto.Auth.Claim.AdminPortal && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))
  ) {
    return <Navigate to="/" />
  }

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.customers}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.customerReport}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersReport />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.customers}/:customerId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.ecomProducts}/:ecomProductId`} // where is AppRoutes
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.ecomProducts} // Where is AppRoutes?
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.products}/:productId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProductV2Details />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.products}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProductV2List />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.carts}/:cartId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CartDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.carts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CartList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.orders}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <OrderList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.payments}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <PaymentList />
            </PrivateRoute>
          }
        />
        <Route path={AppRoutes.dashboard} element={<PrivateRoute>{/* <Dashboard /> */}</PrivateRoute>} />
        <Route
          path={AppRoutes.projects}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/new`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject isNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/:projectId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrders}/:supplierOrderId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrder />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrders}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderDesigns}/:supplierOrderDesignId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderDesign />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderDesigns}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderDesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/:projectId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTaskHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingHistory />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.invoices}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <InvoiceList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.invoices}/:invoiceId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />

        <Route path={`${AppRoutes.invoices}/:invoiceId/payment-completed/:checkoutSessionId`} element={<InvoicePaymentCompleted />} />
        <Route path={`${AppRoutes.invoices}/:invoiceId/payment-failed/:checkoutSessionId`} element={<InvoicePaymentFailed />} />

        <Route
          path={`${AppRoutes.supplierOrderFulfillments}/:supplierOrderFulfillmentId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillment />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderFulfillments}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillmentList />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.supplierOrderShippings}/:supplierOrderShippingId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShipping />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderShippings}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShippingList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.pluginFulfillmentList}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentPluginList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.pluginShippingList}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingPluginList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.newPluginFulfillment}
          element={
            <PrivateRoute>
              <FulfillmentPlugin />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.pluginFulfillment}/:fulfillmentPluginId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentPlugin />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.newPluginShipping}
          element={
            <PrivateRoute>
              <ShippingPlugin />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.pluginShipping}/:shippingPluginId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingPlugin />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.pluginFulfillment}/:fulfillmentPluginId/supplier-select/:supplierSelectId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditSupplierSelect />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.pluginFulfillment}/:fulfillmentPluginId/supplier-select/create`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditSupplierSelect isNew />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.settings}/:supplierId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierSettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.attributes}/:attributeId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.SystemAdmin]}>
              <AttributeDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.attributes}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <AttributeList />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.techniques}/:techniqueId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.SystemAdmin]}>
              <TechniqueDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.techniques}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <TechniqueList />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.categories}/:categoryId/:subcategoryId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.SystemAdmin]}>
              <SubcategoryDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.categories}/:categoryId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.SystemAdmin]}>
              <CategoryDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.categories}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CategoryList />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.customGateway}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomGatewaySettingsList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.customGateway}/:customGatewaySettingId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomGatewaySettingDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.sagas}
          element={
            <PrivateRoute>
              <SagaList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.sagas}/:sagaId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.SystemAdmin]}>
              <SagaDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.users}
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.users}/:userId`}
          element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={`${AppRoutes.createUser}`}
          element={
            <PrivateRoute>
              <CreateUser />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
