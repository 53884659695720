import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Routes, Route } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import AppRoutes from './AppRoutes'
import Layout from '../Layout'

const CartList = lazy(() => import('interfaces/sharedPages/CartList'))
const CartDetails = lazy(() => import('interfaces/sharedPages/CartDetails'))
const CustomersList = lazy(() => import('interfaces/sharedPages/CustomerList'))
const CustomersReport = lazy(() => import('interfaces/sharedPages/CustomersReport'))
const CustomerDetails = lazy(() => import('interfaces/sharedPages/CustomerDetails'))
const CustomGatewaySettingsList = lazy(() => import('interfaces/sharedPages/CustomGatewaySettingsList'))
const CustomGatewaySettingDetails = lazy(() => import('interfaces/sharedPages/CustomGatewaySettingDetails'))
const Design = lazy(() => import('interfaces/sharedPages/Design'))
const DesignList = lazy(() => import('interfaces/sharedPages/DesignList'))
const EcomProductList = lazy(() => import('interfaces/sharedPages/EcomProductList'))
const EcomProduct = lazy(() => import('interfaces/sharedPages/EcomProduct'))
const EditProject = lazy(() => import('interfaces/sharedPages/EditProject'))
const InvoiceDetails = lazy(() => import('interfaces/sharedPages/InvoiceDetails'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))
const ProjectList = lazy(() => import('interfaces/sharedPages/ProjectList'))
const ProjectHistory = lazy(() => import('interfaces/sharedPages/ProjectHistory'))
const MyDesignRequests = lazy(() => import('interfaces/sharedPages/Dashboard'))
const DesignRequest = lazy(() => import('interfaces/sharedPages/DesignRequest'))
const DesignRequestHistory = lazy(() => import('interfaces/sharedPages/DesignRequestHistory'))
const DesignRequestList = lazy(() => import('interfaces/sharedPages/DesignRequestList'))
const FulfillmentList = lazy(() => import('interfaces/sharedPages/FulfillmentList'))
const FulfillmentTask = lazy(() => import('interfaces/sharedPages/FulfillmentTask'))
const FulfillmentTaskHistory = lazy(() => import('interfaces/sharedPages/FulfillmentHistory'))
const ShippingList = lazy(() => import('interfaces/sharedPages/ShippingList'))
const ShippingTask = lazy(() => import('interfaces/sharedPages/ShippingTask'))
const ShippingHistory = lazy(() => import('interfaces/sharedPages/ShippingHistory'))
const ShipstationSettingsList = lazy(() => import('interfaces/sharedPages/ShipstationSettingsList'))
const ShipstationSettingDetails = lazy(() => import('interfaces/sharedPages/ShipstationSettingDetails'))
const ProductV2List = lazy(() => import('interfaces/sharedPages/ProductsList'))
const ProductV2Details = lazy(() => import('interfaces/sharedPages/ProductDetails'))
const SupplierOrder = lazy(() => import('interfaces/sharedPages/SupplierOrder'))
const SupplierOrderList = lazy(() => import('interfaces/sharedPages/SupplierOrderList'))
const SupplierOrderDesign = lazy(() => import('interfaces/sharedPages/SupplierOrderDesign'))
const SupplierOrderDesignList = lazy(() => import('interfaces/sharedPages/SupplierOrderDesignList'))
const SupplierOrderShipping = lazy(() => import('interfaces/sharedPages/SupplierOrderShipping'))
const SupplierOrderShippingList = lazy(() => import('interfaces/sharedPages/SupplierOrderShippingList'))
const SupplierOrderFulfillment = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillment'))
const SourceHistory = lazy(() => import('interfaces/sharedPages/SourceHistory'))
const SupplierOrderFulfillmentList = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillmentList'))
const SupplierProduct = lazy(() => import('interfaces/sharedPages/SupplierProduct'))
const SupplierProductList = lazy(() => import('interfaces/sharedPages/SupplierProductList'))
const SupplierOrderFulfillmentTaskList = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillmentTaskList'))
const SupplierOrderFulfillmentTaskDetails = lazy(() => import('interfaces/sharedPages/SupplierOrderFulfillmentTaskDetails'))
const SupplierOrderShippingTaskList = lazy(() => import('interfaces/sharedPages/SupplierOrderShippingTaskList'))
const SupplierOrderShippingTaskDetails = lazy(() => import('interfaces/sharedPages/SupplierOrderShippingTaskDetails'))
const SupplierSettingsPage = lazy(() => import('interfaces/sharedPages/SupplierSettingsPage'))

const Router = () => {
  const [user, isLoading] = useUser()

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route
          path={AppRoutes.customerReport}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersReport />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.customers}/:customerId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.customers}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyEcomProducts}/:ecomProductId`} // where is AppRoutes
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.legacyEcomProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacySupplierProducts}/:supplierProductId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.legacySupplierProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path={`${AppRoutes.legacyProjects}/new`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject isNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyProjects}/:projectId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyProjects}/:projectId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.legacyProjects}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.invoices}/:invoiceId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesign}/:designId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Design />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesign}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesignRequest}/me`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <MyDesignRequests />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesignRequest}/:designRequestId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesignRequest}/:designRequestId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyDesignRequest}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyFulfillment}/:fulfillmentTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyFulfillment}/:fulfillmentTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTaskHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.legacyFulfillment}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyShipping}/:shippingTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.legacyShipping}/:shippingTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.legacyShipping}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierSettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.customGateway}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomGatewaySettingsList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.customGateway}/:customGatewaySettingId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomGatewaySettingDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.shipstation}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShipstationSettingsList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/${AppRoutes.shipstation}/:shipstationSettingId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShipstationSettingDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.products}/:productId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProductV2Details />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.products}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProductV2List />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrders}/:supplierOrderId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrder />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrders}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrder} />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrders}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderFulfillments}/:supplierOrderFulfillmentId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillment />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderFulfillments}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrderFulfillment} />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderFulfillmentTasks}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrderFulfillmentTask} />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderFulfillments}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillmentList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderShippings}/:supplierOrderShippingId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShipping />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderShippings}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrderShipping} />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderShippings}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShippingList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderDesigns}/:supplierOrderDesignId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderDesign />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderDesigns}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrderDesign} />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderDesigns}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderDesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.carts}/:cartId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CartDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.carts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CartList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderShippingTasks}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShippingTaskList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierOrderShippingTasks}/:sourceId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SourceHistory sourceType={Pto.HistoryReports.Source.SupplierOrderShippingTask} />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderShippingTaskDetails}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderShippingTaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderFulfillmentTasks}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillmentTaskList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierOrderFulfillmentTaskDetails}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierOrderFulfillmentTaskDetails />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
